import { ROUTES } from '@/constants/routes'
import { PERMISSION_NAMES } from '@/constants/workspace/permissionSettings'

export default function checkPermissions({
    next, store, to, router,
}) {
    const { meta = {} } = to
    const { permission, reference } = meta

    if (store.isVendor) {
        switch (reference) {
        case ROUTES.manageNumbers:
            return store.checkPermission(PERMISSION_NAMES.create_numbers)
                ? next()
                : router.push({ name: ROUTES.notFound })
        }
    }

    if (store.isBuyer && reference === ROUTES.manageDestinations) {
        return next()
    }

    if (!store.checkPermission(permission)) {
        return router.push({ name: ROUTES.notFound })
    }

    next()
}
